import React from "react";
import DataGrid, { Column, Editing, Paging, RequiredRule, MasterDetail, RemoteOperations, FilterRow } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { nearMissEventsLogsColumns, nearMissEventsLogsColumnWidths, portfolioNearMissEventsLogsColumnWidths } from "../Models/logsColumns.model";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import ShowWithEventLogsDocuments from "components/ContractInFocus/HAS/ShowWithEventLogsDocuments";

const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/near-miss-event-logs/`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/near-miss-event-logs/`;

const NearMissEventLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        contract,
        portfolio,
        styles,
        pageSize,
        remoteOperations,
        filterRow,
        showContractsColumn,
        contractField
    }) => {
    const thisShowContractsColumn = portfolio || showContractsColumn;
    const columnWidths = thisShowContractsColumn ? portfolioNearMissEventsLogsColumnWidths : nearMissEventsLogsColumnWidths;
    const thisContractField = contractField || 'contract' // for portfolio it can just be contract because contract will be choicefield with contract_ref as display
    return (

        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={getEventEditorPreparing(metadata)} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    style={styles}
                >
                    {pageSize && <Paging defaultPageSize={pageSize} enabled={!!pageSize} />}
                    {remoteOperations && <RemoteOperations
                        groupPaging={false}
                        grouping={true}
                        filtering={true}
                        paging={!!pageSize}
                    />}
                    {filterRow && <FilterRow visible={true}></FilterRow>}
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                    />
                    {thisShowContractsColumn && <Column
                        {...getColumnPropsExt(thisContractField)}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>}
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('investigation_completed')}
                        width={columnWidths['investigation_completed']}
                    >
                    </Column>
                    {/* <Column {...getColumnPropsExt('resolved_on', { min: "2020/04/12", dateOutOfRangeMessage: "hey this a'int right" })} width={110} /> */}
                    <Column {...getColumnPropsExt('resolved_on', { max: Date.now(), dateOutOfRangeMessage: "resolved on cannot be in the future or before the date raised" })}
                        width={columnWidths['resolved_on']}
                    />
                    <Column {...getColumnPropsExt('resolution')}
                        width={columnWidths['resolution']}
                    />

                    <MasterDetail
                        enabled={true}
                        component={
                            (e) => {
                                return (<ShowWithEventLogsDocuments
                                    data={e.data.data}
                                    parentType="near_miss"
                                />)
                            }
                        }
                    />

                </DataGrid>
            )}
        </div>

    );
};

const getWrappedNearMissEventLogs = ({ pageSize, contract, portfolio, sectionNumberMap, remoteOperations, csvFileNameBase, showContractsColumn, contractField }: Partial<NewLogsProps>) => {
    return () => {
        let getEndpoint = (): string => `near-miss-event-logs/`;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={NearMissEventLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={nearMissEventsLogsColumns}
            logsHandle='NearMissEventLogs'
            logsTitle={reportSubtitles.NearMissEvents}
            sectionNumberMap={sectionNumberMap}
            pageSize={pageSize}
            remoteOperations={remoteOperations}
            csvFileNameBase={csvFileNameBase}
            showContractsColumn={showContractsColumn}
            contractField={contractField}
        />
    }
}

export default getWrappedNearMissEventLogs;
